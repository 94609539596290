var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "projects" },
        _vm._l(_vm.projects, function(project) {
          return _c(
            "div",
            {
              key: project.name,
              staticClass: "project",
              style: { background: project.background }
            },
            [
              project.comingSoon
                ? _c("div", { staticClass: "coming-soon" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(project.name))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "logo" }, [
                      _c("img", { attrs: { src: "./assets/" + project.logo } })
                    ]),
                    _vm._v(" "),
                    _vm._m(2, true)
                  ])
                : _vm._e(),
              _vm._v(" "),
              !project.comingSoon
                ? _c(
                    "a",
                    { staticClass: "front", attrs: { href: project.url } },
                    [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(project.name))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "logo" }, [
                        _c("img", {
                          attrs: { src: "./assets/" + project.logo }
                        })
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !project.comingSoon
                ? _c(
                    "a",
                    { staticClass: "overview", attrs: { href: project.url } },
                    [
                      _c("vue-markdown", {
                        attrs: { source: project.rawMarkdown }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", [
      _c("img", { attrs: { src: "assets/DroplitLabsLogo.svg" } }),
      _vm._v(" "),
      _c("div", { staticClass: "tag-line" }, [
        _vm._v("\n            We "),
        _c("span", { staticClass: "icon" }, [
          _c("img", { attrs: { src: "assets/noun_Heart_2070148_00e49b.svg" } })
        ]),
        _vm._v(" Open Source\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "intro" }, [
      _vm._v("\n        There are few things we've built at "),
      _c("a", { attrs: { href: "https://droplit.io" } }, [
        _vm._v("droplit.io")
      ]),
      _vm._v(
        " that we love so much, we decided to go the extra mile to polish, document, and share with the world.\n    "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner" }, [
      _c("img", { attrs: { src: "assets/coming-soon-web.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }