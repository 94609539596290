













import { router } from "./index";
import Vue from "vue";

export default Vue.extend({});
