








































import { Vue, Component } from 'vue-property-decorator';

import VueMarkdown from 'vue-markdown'

import axios from 'axios';

interface Project {
    name: string;
    url: string;
    overview?: string; // Link to raw github md file
    comingSoon?: boolean;
    logo: string; // File name in ./asstes
    background?: string; // Hex color. i.e. #123abc 
    rawMarkdown?: string // Markdown rendered. Will override "overview" if set;
}

@Component({
    components: {
        VueMarkdown
    }
})
export default class Home extends Vue {
    projects: Project[] = [
        {
            name: 'Universal-WS',
            url: 'https://github.com/droplit/universal-ws',
            overview: 'https://raw.githubusercontent.com/droplit/universal-ws/master/overview.md',
            logo: 'UniversalWSLogo.svg',
        },
        {
            name: 'worqr',
            url: 'https://github.com/droplit/worqr',
            overview: 'https://raw.githubusercontent.com/droplit/worqr/master/overview.md',
            logo: 'WorqrLogo.svg',
        },
        {
            name: 'bashr',
            url: 'https://github.com/droplit/bashr',
            overview: 'https://raw.githubusercontent.com/droplit/bashr/master/overview.md',
            logo: 'BashrLogo.svg',
            background: '#E3E3E3'
        },
        {
            name: 'SerialTransport',
            comingSoon: true,
            url: 'https://github.com/droplit/serial-transport',
            logo: 'SerialTransportLogo.svg',
        }
    ];
    created() {
        for (const project of this.projects) {
            if (project.overview && !project.rawMarkdown) {
                axios({
                    url: project.overview,
                    method: 'GET',
                }).then((response) => {
                    project.rawMarkdown = response.data;
                    this.$forceUpdate();
                });
            }
        }
    }
}
