var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("router-view", { key: _vm.$route.fullPath }),
      _vm._v(" "),
      _c("footer", [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "copyright" }, [
          _vm._v(
            "\n            " +
              _vm._s("© Droplit, Inc. " + new Date().getFullYear()) +
              "\n        "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "out" }, [
      _vm._v("Brought to you by the IoT platform team at "),
      _c("a", { attrs: { href: "https://droplit.io" } }, [_vm._v("droplit.io")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }